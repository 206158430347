.markdown {
    @apply text-sm;
    @apply leading-relaxed;
  }
  
  .markdown p,
  .markdown div,
  .markdown ul,
  .markdown ol,
  .markdown blockquote {
    @apply mb-4;
  }

  .markdown li {
    @apply list-disc;
    @apply ml-4;
    @apply pl-2;
  }

  .markdown h1 {
    @apply text-2xl; 
    @apply mb-4;
    @apply leading-snug;
    @apply font-bold;
  }
  
  .markdown h2 {
    @apply text-xl; 
    @apply mb-4; 
    @apply leading-snug;
    @apply font-bold;
  }
  
  .markdown h3 {
    @apply text-lg;
    @apply mb-8;
    @apply leading-snug;
    @apply font-bold;
  }

  .markdown p strong, .markdown div strong {
    @apply font-bold;
  }